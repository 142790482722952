/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'DINNextRoundedLTPro NotoSansCJKtc NotoSansTC' !important;
}

@font-face {
  font-family: 'NotoSansCJKtc';
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansCJKtc';
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansCJKtc';
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Bold.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'DINNextRoundedLTPro';
  src: local('DINNextRoundedLTPro'), url(./assets/fonts/DINNextRoundedLTPro-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'DINNextRoundedLTPro';
  src: local('DINNextRoundedLTPro'), url(./assets/fonts/DINNextRoundedLTPro-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansCJKsc';
  src: local('NotoSansCJKsc'), url(./assets/fonts/NotoSansCJKsc-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansTC';
  src: local('NotoSansTC'), url(./assets/fonts/NotoSansTC-Bold.otf) format('opentype');
  font-weight: 700;
}


