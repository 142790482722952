
body {
  font-family: NotoSansCJKtc, DINNextRoundedLTPro, NotoSansTC, DFYuanHK-W8 !important;
}

@font-face {
  font-family: NotoSansCJKtc;
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: DFYuanHK-W8;
  src: local('DFYuanHK-W8'), url(./assets/fonts/DFYuanHK-W8.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: NotoSansCJKtc;
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: NotoSansCJKtc;
  src: local('NotoSansCJKtc'), url(./assets/fonts/NotoSansCJKtc-Bold.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: DINNextRoundedLTPro;
  src: local('DINNextRoundedLTPro'), url(./assets/fonts/DINNextRoundedLTPro-Regular.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: DINNextRoundedLTPro;
  src: local('DINNextRoundedLTPro'), url(./assets/fonts/DINNextRoundedLTPro-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: NotoSansCJKsc;
  src: local('NotoSansCJKsc'), url(./assets/fonts/NotoSansCJKsc-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: NotoSansTC;
  src: local('NotoSansTC'), url(./assets/fonts/NotoSansTC-Bold.otf) format('opentype');
  font-weight: 700;
}

/* .drawer .ant-modal-body {
  overflow: scroll;
} */


.rbc-calendar .rbc-event-label {
    font-size: 9px;
    /* transform: scale(0.75); */
  }

.rbc-calendar .rbc-event-content {
    font-size: 11px;
    /* transform: scale(0.75); */
  }

.rbc-calendar .rbc-event {
    padding: 2px 1px;
  }  
  
.ant-layout-header {
  padding: 0 30px;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.ant-menu-item:hover {
  transform: scale(1.03);
}

.home-btn:hover {
  background-color: #d7633d !important;
  color: #fff !important;
  border-color: transparent;
}

.banner-slider .slick-list {
  width: 100vw;
  height: 90vh;
  /* max-height: 800px; */
}

.banner-slider-mobile .slick-list {
  width: 100vw;
  height: 100vh;
  /* max-height: 800px; */
}
.drawer {
  border-radius: 15px !important;
}

.drawer .ant-modal-content {
  background-color: #ffffff00;
}

.questionnaire_form .ant-form-item-explain{
  color: #fff
}

.question-radio {
  margin: auto;
}

.question-radio label {
  display: inline-grid;
  margin: 0px;
  grid-template-areas: 
  "top"
  "bottom"
}

.question-radio .ant-radio {
  grid-area: bottom;
  margin: auto;
  font-size: 12px;
}

.custom-radio .ant-radio-wrapper {
  width: auto;
  padding-bottom: 10px;
}

.custom-radio.question-radio .ant-radio-wrapper {
  width: 20%;
}

.custom-radio .ant-radio-wrapper > span:last-child {
  /* padding: 0px; */
  /* padding-bottom: 10px; */
  color: #fff;
  font-weight: bold;
  font-size:12px;
}



.custom-radio.question-radio .ant-radio-wrapper > span:last-child {
  grid-area: top;
  padding: 0px;
  padding-bottom: 10px;
  width: auto;
  text-align: center;
  color: #6e5333;
  /* transform: scale(0.833); /* 10/12=0.833, font-size:10px */
}


.custom-radio .ant-radio-inner {
  background-color: transparent;
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-color: #fff;
}

.custom-radio .ant-radio-inner::after {
  background-color: white;
  width: 10px;
  height: 10px;
  border-width: 2px;
}

 .custom-radio .ant-radio:hover .ant-radio-inner, .custom-radio .ant-radio-input:focus + .ant-radio-inner {
  border: 1px solid white;
  border-width: 2px;
}

.tnc-content .ql-container {
  font-family: NotoSansCJKtc, DINNextRoundedLTPro, NotoSansTC, DFYuanHK-W8 !important;
}

.tnc-content .ql-editor {
  padding: 0px;
}

.tnc-content p {
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  font-weight: '500'
}

.custom-ul-bullet {
  list-style: none;
  padding: 0px;
  counter-reset: foo;
  display: table;
}

.custom-ul-bullet > li {
  counter-increment: foo;
  display: table-row;
}

.custom-ul-bullet > li span {
  display: block;
  padding-left: 20px;
  line-height: 1.8;
}

.custom-ul-bullet li:before {
  background-image: url('./assets/330/bullet.png');
  background-size: 15px 15px;
  transform: translateY(20%);
  background-repeat: no-repeat;
  width: 20px; 
  height: 20px;
  content:"";
  display: table-cell; /* aha! */
}

.custom-input::placeholder {
  color: #5f58586e;
}

.ant-checkbox .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 22px;
  height: 22px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 8px;
  height: 12.8px;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) { 
  .drawer-content-body {
    /* padding: 10px 10%; */
  }

  .custom-radio.question-radio .ant-radio-wrapper > span:last-child {
    width: 15vw;
    transform: scale(0.833);
  }

  .custom-radio.question-radio.part-2-en .ant-radio-wrapper > span:last-child {
    display: block;
    word-wrap:break-word;
    width: 100%;
    white-space: normal;
    height: 70px;;
  }
 }

 /* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
  .drawer-content-body {
    /* padding: 10px 8% */
  }

  .custom-radio.question-radio .ant-radio-wrapper > span:last-child {
    width: auto;
    transform: scale(0.833);
  }

  .custom-radio.question-radio.part-2-en .ant-radio-wrapper > span:last-child {
    display: block;
    word-wrap:break-word;
    width: 100%;
    white-space: normal;
    height: 70px;;
  }
  
}


/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
  .drawer-content-body {
    /* padding: 10px 8% */
  }

  .custom-radio.question-radio .ant-radio-wrapper > span:last-child {
    width: auto;
  }

  .custom-radio.question-radio.part-2-en .ant-radio-wrapper > span:last-child {
    display: block;
    word-wrap:break-word;
    width: 100%;
    white-space: normal;
    height: 70px;;
  }
  
 }

 /* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
  .drawer-content-body {
    padding: 10px 10%
  }

  .custom-radio.question-radio .ant-radio-wrapper > span:last-child {
    width: auto;
  }

  .custom-radio.question-radio.part-2-en .ant-radio-wrapper > span:last-child {
    display: block;
    word-wrap:break-word;
    width: 100%;
    white-space: normal;
    height: 60px;;
  }
  
 }
 /* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .drawer-content-body {
    padding: 10px 10%
  }

  .custom-radio.question-radio .ant-radio-wrapper > span:last-child {
    width: auto;
  }

  .custom-radio.question-radio.part-2-en .ant-radio-wrapper > span:last-child {
    display: block;
    word-wrap:break-word;
    width: 100%;
    white-space: normal;
    height: 50px;;
  }
  
 }

